import React from "react";
import Footer from "src/components/Footer_uk";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/uk/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/uk/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a
                          className="first"
                          href="/uk/our-products/moisturising/"
                        >
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/uk/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/uk/our-products/allergy/">Allergy</a>
                      </li>                      
                      <li>
                        <a href="/uk/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/aftersun/">After sun</a>
                      </li>                      
                    </ul>
                  </li>
                  <li className="nav-selected nav-path-selected">
                    <a className="es_header" href="/uk/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/where-to-buy/" target="_self">
                      Where to buy
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-heritage/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-heritage/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestra-historia/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/a_nossa_historia/">PT</a>
                      </li>
                      <li>
                        <a href="/it/our-heritage/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-heritage/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="heritage-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt ="A specialist sun care brand is born"
                    src="/assets/images/our_heritage_1680x405_topLarge.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/our_heritage_960x405_topSmalla.jpg"
                  />
                </div>
              </div>
            </section>
            
            <div className="page-heritage__intro">
            <h1>Our Heritage</h1>
              <h3>A specialist sun care brand is born</h3>
              <p>
                For over 70 years, PIZ BUIN<sup>®</sup> has been providing sun
                lovers everywhere with suncare solutions that help them achieve
                the perfect balance between getting a beautiful tan and the
                protection they need. In fact, PIZ BUIN<sup>®</sup> was one of
                the first to make tanning safer by implementing the Sun
                Protection Factor (SPF) system in the 1960's. So wherever
                there's sun, whether it's at the beach, on a mountain or in the
                city, PIZ BUIN<sup>®</sup> has been there helping people to
                enjoy life in the sun, safely.
              </p>
              <p>
                After suffering overexposure to the sun while climbing the Piz
                Buin alpine peak in 1938, chemistry student, Franz Greiter,
                developed one of the world's first sun protection products in
                1946, the PIZ BUIN<sup>®</sup> Gletscher Creme, or Glacier
                Cream. Working with his wife, Marga, a trained beautician, they
                developed a specialist sun care brand dedicated to developing
                cutting edge sun protection with luxurious skincare for anyone
                who wanted to enjoy life in the sun. For more than 70 years PIZ
                BUIN<sup>®</sup> has been providing sun lovers everywhere with
                uncompromising protection so they can get that beautiful summer
                colour they're after - a colour that has always inspired people
                to say: "Where Have You Been!<sup>®</sup>"
              </p>
            </div>
            <section id="content">
              <div id="loading" />
              <div className="innerContent">
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/InstantGlow_Heritage.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">2017</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    <strong>Instant Glow</strong>
                    <br />
                    <br />
                    Introduction of new PIZ BUIN INSTANT GLOW<sup>®</sup> Skin
                    Illuminating Sun Lotion.
                    <br />
                    This fast absorbing, non greasy, non sticky lotion
                    moisturizes the skin, giving it<br /> that beautiful summer
                    shimmer instantly and all summer long – all without
                    compromising on protection.
                    <br />
                    <br />
                  </p>
                  <p style={{
                                  textAlign:"center"}} />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/70_Years_Celebration_640_02.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">2016</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    <strong>70 Years – Bloggers</strong>
                  </p>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN<sup>®</sup> celebrates 70 years of sun care
                    experience by sending five beauty,<br /> travel and lifestyle
                    bloggers to explore Costa Rica and share their experiences<br />
                    as the first ever PIZ BUIN<sup>®</sup> Sun Ambassadors.
                  </p>
                  <p style={{
                                  textAlign:"center"}}>Meet the bloggers</p>
                  <p style={{
                                  textAlign:"center"}}>
                    <a href="http://www.fashionmumblr.com" target="_blank">
                      Fashion Mumblr
                    </a>
                    <br />
                    <br />{" "}
                    <a href="http://www.ireneccloset.com" target="_blank">
                      Irene's Closet
                    </a>
                    <br />
                    <br />{" "}
                    <a
                      href="http://www.themuseandtheladybug.gr"
                      target="_blank"
                    >
                      The Muse And The Ladybug
                    </a>
                  </p>
                  <p style={{
                                  textAlign:"center"}}>
                    <span className="footNote">
                      <em>
                        Clicking on any of the links above will take you to a
                        web site the content for which PIZ BUIN<sup>®</sup> is
                        not responsible<br /> and to which this website’s Privacy
                        Policy does not apply. We encourage you to read the
                        Privacy Policy of every<br /> web site you visit.
                      </em>
                    </span>
                  </p>
                  <p style={{
                                  textAlign:"center"}} />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/Protect_and_Cool_Frozen_640_02.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">2016</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    <strong>Protect &amp; Cool</strong>
                  </p>
                  <p style={{
                                  textAlign:"center"}}>
                    Introduction of PIZ BUIN PROTECT &amp; COOL<sup>®</sup>{" "}
                    refreshing sun mousse.
                    <br />
                    Unbelievably Fresh. Instantly Protected.
                    <br />
                    Feels like you are not wearing sun screen at all!
                  </p>
                  <div> </div>
                  <p style={{
                                  textAlign:"center"}} />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/Sun_Finder_640px_02.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">2016</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    <strong>70 Years - Sun Finder</strong>
                  </p>
                  <p style={{
                                  textAlign:"center"}}>
                    Launch of the PIZ BUIN<sup>®</sup> Sun Finder - a web app
                    filled with experiences and<br /> recommendations about the best
                    places under the sun.
                  </p>
                  <p style={{
                                  textAlign:"center"}}>
                    Visit the PIZ BUIN<sup>®</sup> Sun Finder
                  </p>
                  <div> </div>
                  <p style={{
                                  textAlign:"center"}} />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/2015-KV-Instant-Glow-Vertical.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">2015</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Introduction of the
                    <br /> NEW PIZ BUIN INSTANT GLOW<sup>®</sup> Skin
                    Illuminating Sun Spray.
                    <br />
                    Protected and beautiful - Instantly and all summer long.
                  </p>
                  <p style={{
                                  textAlign:"center"}} />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2014b.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">2014</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN WET SKIN<sup>®</sup> Transparent Sun Spray summer
                    campaign production in<br /> Thailand.{" "}
                  </p>
                  <p style={{
                                  textAlign:"center"}} />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2014.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">2014</h2>
                  <p style={{
                                  textAlign:"center"}}>
                    Introducing PIZ BUIN<sup>®</sup> ULTRA LIGHT Dry Touch
                    Fluids. Effective protection that<br /> dries instantly and feels
                    weightless.
                  </p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2013.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">2013</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Introduction of PIZ BUIN WET SKIN<sup>®</sup> one of the
                    first sunscreens that can be<br /> applied directly on wet skin.
                  </p>
                  <p style={{
                                  textAlign:"center"}} />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2012.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">2012</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Launch of PIZ BUIN TAN &amp; PROTECT<sup>®</sup>. For a
                    faster*, more beautiful tan ... <br />safely. <br />
                    <span className="footNote">
                      *Contains a technology that enhances natural tanning
                    </span>
                  </p>
                  <p style={{
                                  textAlign:"center"}} />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2011.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">2011</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    65th anniversary and launch of the NEW Glacier Cream. The
                    ultimate frontier<br /> for PIZ BUIN<sup>®</sup> in sun protection
                    technology.
                  </p>
                  <p style={{
                                  textAlign:"center"}} />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2010.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">2010</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN<sup>®</sup> MOUNTAIN, now with Cold Shield Complex
                    and Edelweiss extract.<br /> PIZ BUIN<sup>®</sup> MOUNTAIN.
                    Special* protection for high altitude sun, cold and wind<br />
                    conditions. <br />
                    <span className="footNote">
                      *Designed to protect from sun, cold and wind
                    </span>
                  </p>
                  <p style={{
                                  textAlign:"center"}} />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2009.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">2009</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN<sup>®</sup> ALLERGY now with Calmanelle<sup>®</sup>{" "}
                    a unique shield complex, proven to<br /> help strengthen your
                    skin's resilience*. <br />
                    <span className="footNote"> *In vitro test</span>
                  </p>
                  <p style={{
                                  textAlign:"center"}} />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2008.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">2008</h2>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN<sup>®</sup> sun care range is upgraded to the new
                    breakthrough sun protection<br /> technology Helioplex<sup>™</sup>
                    .
                  </p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2007.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">2007</h2>
                  <p style={{
                                  textAlign:"center"}}>
                    Launch of Tan Intensifier - a range of products with an
                    innovative formulation<br /> that facilitates faster natural
                    tanning without compromising on protection.
                  </p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2000.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">2000</h2>
                  <p style={{
                                  textAlign:"center"}}>Deep tanning gives way to a 'sun kissed glow'.</p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_1993.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">1993</h2>
                  <p style={{
                                  textAlign:"center"}}>
                    The first PIZ BUIN<sup>®</sup> triple protection sun care
                    with Vitamin E.
                  </p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_1984.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">1984</h2>
                  <p style={{
                                  textAlign:"center"}}>
                    "Sun goddess" visuals strengthen our iconic brand image.
                  </p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_1974.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">1974</h2>
                  <p style={{
                                  textAlign:"center"}}>
                    One of the first to introduce water resistant sun care
                    products.
                  </p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_1962b.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">1962</h2>
                  <p style={{
                                  textAlign:"center"}}>Conducting tests in the Swiss-Austrian Alps.</p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_1962a.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">1962</h2>
                  <p style={{
                                  textAlign:"center"}}>
                    One of the first to implement the sun protection factor
                    (SPF).
                  </p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_1946.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">1946</h2>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN<sup>®</sup> launches its first sun protection
                    product.
                  </p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_1938.jpg" alt="A specialist sun care brand is born" />
                  </figure>
                  <h2 className="year_header">1938</h2>
                  <p style={{
                                  textAlign:"center"}}>Dr. Franz Greiter climbing the Piz Buin mountain.</p>
                </div>
                <span className="vline" />
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.instagram.com/PizBuin_UK/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        
        
      </div>
    );
  }
}

export default Page;
